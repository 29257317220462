import { Grid, GridSize, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { BlogArticle, BlogCard } from "./BlogCard";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    justifyContent: "center",
  },
  noResults: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: "center",
  },
}));

export type BlogHubProps = {
  articleLoadCount: number;
  articles: BlogArticle[];
  noResultsMessage: string;
  blogsPerRow?: number;
};

export const BlogHub: React.FC<BlogHubProps> = ({
  articleLoadCount,
  articles,
  noResultsMessage,
  blogsPerRow = 3,
}) => {
  const classes = useStyles();
  const desktopGrid = Math.round(
    12 / (blogsPerRow > 4 || blogsPerRow < 1 ? 4 : blogsPerRow)
  ) as GridSize;

  return (
    <>
      {articles?.length > 0 ? (
        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="stretch"
          className={classes.container}
        >
          {articles.slice(0, articleLoadCount).map((article, i) => (
            <Grid item xs={12} md={desktopGrid} key={i}>
              <BlogCard {...article} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid item xs={12} className={classes.noResults}>
          <Typography variant="body1" color="secondary">
            <strong>{noResultsMessage}</strong>
          </Typography>
        </Grid>
      )}
    </>
  );
};

export * from "./BlogCard";
