import React from "react";
import { Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ContentCard } from "../SlidingContentCard";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

export type BlogArticle = {
  title: string;
  subtitle: string;
  imageURL: string;
  categoryName: string;
  categoryColour: string;
  blogURL: string;
};

export const BlogCard: React.FC<BlogArticle> = ({
  title,
  subtitle,
  imageURL,
  categoryName,
  categoryColour,
  blogURL,
}) => {
  const classes = useStyles();

  return (
    <ContentCard media={{ imgUrl: imageURL }}>
      <Typography variant="body1" style={{ color: categoryColour }}>
        <strong>{categoryName}</strong>
      </Typography>
      <Link
        variant="h4"
        href={blogURL}
        color="textPrimary"
        className={classes.title}
        underline="hover"
      >
        {title}
      </Link>
      <Typography variant="body2">{subtitle}</Typography>
    </ContentCard>
  );
};
